import { Component } from '@angular/core';


/**
 * @title Navbar
 */
@Component({
    selector: 'tabs-stretch-example',
    templateUrl: 'tabs-stretch-example.html',
    styleUrls: ['tabs-stretch-example.css']
})
export class TabsStretchExample {}
