import { Component } from '@angular/core';


/**
 * @title Basic Table
 */
@Component({
    selector: 'table-with-borders-example',
    templateUrl: 'table-with-borders-example.html',
    styleUrls: ['table-with-borders-example.css']
})
export class TableWithBordersExample {}
