import { Component } from '@angular/core';


/**
 * @title Basic Input
 */
@Component({
    selector: 'input-number-overview-example',
    templateUrl: 'input-number-overview-example.html',
    styleUrls: ['input-number-overview-example.css']
})
export class InputNumberOverviewExample {
    value = '';
}
