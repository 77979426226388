import { Component } from '@angular/core';


/**
 * @title Basic tooltip
 */
@Component({
    selector: 'tooltip-overview-example',
    templateUrl: 'tooltip-overview-example.html',
    styleUrls: ['tooltip-overview-example.css']
})
export class TooltipOverviewExample {}
