import { Component } from '@angular/core';


/**
 * @title Badges
 */
@Component({
    selector: 'badges-overview-example',
    templateUrl: 'badges-overview-example.html',
    styleUrls: ['badges-overview-example.css']
})
export class BadgesOverviewExample {
}
